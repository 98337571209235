import * as React from "react";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import BannerModule from "../components/BannerModule/BannerModule";
import BasicListModule from "../components/BasicListModule/BasicListModule";
import PerksModule from "../components/PerksModule/PerksModule";
import Perk from "../components/PerksModule/Perk";
import Features from "../components/Features/Features";

const Index = () => {
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule
          title="Hi 👋 my name is Bobby Howe"
          subTitle="I am a web developer with 10+ years of experience, based in Sheffield."
        />
        <BasicListModule
          title="Need any help with?"
          listItems={[
            "– Web Development",
            "– React",
            "– Ecommerce",
            "– Content management systems",
          ]}
          link="/cv"
          linkText="See My Latest Work"
        />
        {/* <PerksModule>
          <Perk title="The Title" content="The content" />
        </PerksModule> */}
        <Features title="CV" introduction="Discover My Latest roles." />
        {/* <LatestPosts
          title="Latest Posts"
          introduction="Cras scelerisque, tellus sed gravida tincidunt, velit tellus blandit justo, nec viverra augue erat in erat. Maecenas iaculis sed purus non fringilla."
        /> */}
      </Layout>
    </>
  );
};

export default Index;
