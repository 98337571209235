import * as React from "react";
import Button from "../Button/Button";
import { BasicListModuleStyles } from "./BasicListModuleStyles";
import { Link } from "gatsby";

const BasicTextModule = ({ title, link, linkText, listItems }) => {
  return (
    <BasicListModuleStyles className="section">
      <div className="container container__tight">
        <div>
          {title && <h2>{title}</h2>}
          {listItems && (
            <ul>
              {listItems.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}

          <Button text={linkText} as={Link} to={link} />
        </div>
      </div>
    </BasicListModuleStyles>
  );
};

export default BasicTextModule;
