import styled from "styled-components";

export const BasicListModuleStyles = styled.section`
  .container {
    ul {
      list-style: none;
    }
    @media (min-width: 768px) {
      > div {
        width: 66.666%;
        max-width: 700px;
      }
    }
  }
`;
